import { AppProvider, i18Init, namespaces } from "@sundae/gov-toolkit";
import ReactDOM from "react-dom/client";
import { useI18N } from "@sundae/react-hooks";
import resourcesToBackend from "i18next-resources-to-backend";

import "./tailwind.scss";

(async () => {
  i18Init(
    (language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`).then(({ default: resources }) =>
        callback(null, resources)
      );
    },
    (options) => {
      options.ns = [...namespaces, "cardanosummit2022"];
      options.debug = false;
      return options;
    }
  );
})();

const App = () => {
  const { i18n } = useI18N("cardanosummit2022");
  i18n.use(
    resourcesToBackend((language, namespace, callback) => {
      const path = `./translations/${language}/${namespace}.json`;
      import(path).then(({ default: resources }) => callback(null, resources));
    })
  );

  return (
    <AppProvider
      brand={{
        images: {
          audit_pending: "/static/images/audit_pending.png",
          casting_a_vote: "/static/images/casting_a_vote.png",
          fatal_error: "/static/images/fatal_error.png",
          not_enough_tokens: "/static/images/not_enough_tokens.png",
          proposal_created: "/static/images/proposal_created.png",
        },
      }}
    />
  );
};

const target = document.querySelector("#app");
const root = target && ReactDOM.createRoot(target);
if (root) {
  root.render(<App />);
}
